import config from '../config';

/** Configuration for the builder.io integration service. */
const builderio = config({
  /** Defaults for all sites and locales. */
  default: {
    /** Default settings for all languages. */
    default: {
      /** Is builder.io enabled for the current brand. */
      enabled: false,
      /** The brand specific public key for the Builder.io service. */
      builderPublicKey: undefined as unknown as string
    }
  },
  'AHNU-US': {
    default: {
      enabled: true,
      builderPublicKey: {
        default: 'd6c45f14bc704bf490dba300da775f7c',
        prod: '92f7cd891ccb4cfb8ff28f707b46e0ab'
      }
    }
  },
  'HOKA-US': {
    default: {
      enabled: true,
      builderPublicKey: '6a5c54ea9db548088b78d2907294d252'
    }
  },
  'UGG-US': {
    default: {
      enabled: true,
      builderPublicKey: '3b910c4ebc5849cf859ff17d81a36c49'
    }
  },
  'UGG-COLLAB-US': {
    default: {
      enabled: true,
      builderPublicKey: '3b910c4ebc5849cf859ff17d81a36c49'
    }
  },
  'TEVA-US': {
    default: {
      enabled: true,
      builderPublicKey: 'd300f4d01af44c52b30e3621e314787e'
    }
  }
});

export default builderio;
