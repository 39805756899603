/* eslint-disable filenames/match-exported -- Needs to alias the default export */
import { Language } from '@/constructs/Language';
import { Country } from '@/constructs/Country';
import {
  SiteCountry,
  regionCountriesMap,
  type ILocale,
  type ConfiguredLocaleString,
  countryLanguagesMap,
  siteCountriesMap,
  type LocaleString
} from '@/constructs/LocaleSchema';
import { Region } from '@/constructs/Region';
import type lang from '@/lang';
import type { Resource } from '@/lang';
import ResourceStrings from '@/lang/ResourceStrings';
import { Nullable, PartialRecord, Primitive } from '@/type-utils';
import type { Path as PathType, PathValue } from '@/type-utils/Path';
import MemoryCache, { GetMethod } from '@/utils/MemoryCache';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import {
  InvalidStateError,
  LikelyMisconfigurationError,
  NotImplementedError
} from '@/utils/errors';
import { InvalidArgumentError } from '@/utils/errors/InvalidArgumentError';
import IntlMessageFormat from 'intl-messageformat';
import type { NextRouter } from 'next/router';
import { type ReactElement } from 'react';
import siteCached from '@/services/utils/siteCached';
import type { Currency } from '../../models/Money';
import { getHook } from '../../utils/react-utils/hook-utils';
import CurrentRequestService from '../CurrentRequestService';
import { EnvironmentService } from '../EnvironmentService';
import { RunContextService } from '../RunContextService';

/**
 * A type alias for the {@link lang} object.
 */
export type StringContainer = typeof lang;

/**
 * Provides data relating to internationalization and localization within the
 * context of the current locale (i.e. the current parameters that define the
 * user's region, country, and/or language).
 */
class I18NService {
  private _resourceStringCache: Nullable<MemoryCache<ResourceStrings>>;
  private _currency: Currency = 'USD' as Currency;

  /** Effectively a cache for ILocale objects. */
  private _ConfiguredLocaleStringRecord: PartialRecord<
    ConfiguredLocaleString | Language | Country,
    ILocale
  > = {};

  /**
   * Sets up the resource string cache when it is requested.
   * This keeps it from running too early.
   * @returns The memory cache associated with the service.
   */
  private get resourceStringCache(): MemoryCache<ResourceStrings> {
    if (!this._resourceStringCache) {
      this._resourceStringCache = new MemoryCache<ResourceStrings>();
    }

    return this._resourceStringCache;
  }

  /**
   * The site currency hard coded to USD.
   * @returns The currency that is currently used on the site.
   */
  public get currency(): Currency {
    return this._currency;
  }

  /**
   * Gets the current locale (language and country) as an object.
   *
   * @returns The locale in use for the current request.
   *
   * `toString()` can be called on this value to get a string representation.
   */
  public get currentLocale(): ILocale {
    // If we're in an isolated environment, such as Storybook, do not attempt to
    // obtain the locale from any of the normal sources. Instead, use `en-US` as
    // the default.
    if ((typeof __isolatedContext__ !== "undefined")) {
      return this.getLocaleFromString('en-US' as ConfiguredLocaleString);
    }

    // If we're on the server...
    if ((typeof window === "undefined")) {
      // This is to make sure we are on the app router.
      if (EnvironmentService.isCachableContext) {
        try {
          const { request } = RunContextService.context;
          const langLocale = request.locale;
          return this.getLocaleFromString(langLocale as ConfiguredLocaleString);
        } catch (e) {
          // When in the pages router, the cache shouldn't exist at all.
          // In the app router it should always fail when used in a client component
          // This is only useful within a server component.
          if (!(e instanceof NotImplementedError)) {
            // If it throws the NotImplementedError we just move on
            // Since it is expected when this is checked outside a server component,
            // but any other error should be rethrown.
            throw e;
          }
        }
      }

      // Try to get the locale from the request (if in a request).
      if (CurrentRequestService.inRequest) {
        const [requestObject] = CurrentRequestService.tryGet();

        if (requestObject) {
          const langLocale = requestObject.locale
            ? requestObject.locale
            : requestObject.queryParams.get('lang');

          if (langLocale) {
            return this.getLocaleFromString(
              langLocale as ConfiguredLocaleString
            );
          }
        }
      }
    }

    // Attempt to get the locale from `useRouter` - this will not work in some
    // environments.
    try {
      const Router = getHook<NextRouter>('Router');
      return this.getLocaleFromString(Router.locale as ConfiguredLocaleString);
    } catch (e) {
      // If `useRouter` failed, then we're unable to determine the locale.
      throw new Error(
        `Could not determine the locale for the current environment.`,
        { cause: e as Error }
      );
    }
  }

  /**
   * Gets the current {@link Region} based on the current {@see Country}.
   * @returns The current {@link Region}.
   * @example
   * I18NService.currentRegion; // 'NA' when the current locale is 'en-US'.
   */
  public get currentRegion(): Region {
    return this.getRegionOfCountry(this.currentLocale.country);
  }

  /**
   * Retrieves the locales allowed for the current site. That is, the list of
   * locales that the current site is available on.
   *
   * For a locale to be considered "allowed" for a specific site, its country
   * and language must be present in the site's mappings at
   * `@/constructs/LocaleSchema`.
   *
   * @see {@link AllowedLocalesForBrand}
   *
   * @returns A list of strings representing the locales allowed for the current
   * site, as
   * [IETF language tags](https://en.wikipedia.org/wiki/IETF_language_tag).
   *
   * **NOTE:** This JSDoc comment uses the updated terminology introduced in
   * [!1638]https://gitlab.com/deckersbrands/headless-nextjs-app/headless-frontend/-/merge_requests/1638.
   *
   * **NOTE 2:** This method will be replaced altogether in the MR mentioned
   * above.
   */
  @siteCached
  public get allowedLocales(): Array<ConfiguredLocaleString> {
    const siteLocales = siteCountriesMap[EnvironmentService.brand];
    const langLocales: Set<ConfiguredLocaleString> = new Set();

    // For every locale configured for this site...
    for (const locale of siteLocales) {
      // Get the "settings"/"mappings" for the current locale from
      // `@/constructs/LocaleSchema`. This will include all of the languages
      // that the locale is configured for.
      const localeSettings = countryLanguagesMap[locale as Country];
      let languageList: Array<string> | undefined;

      // If there is a `languages` property in the map...
      if (Object.hasOwn(localeSettings, 'languages')) {
        // The list of configured languages will be it.
        languageList = (
          localeSettings as unknown as {
            readonly languages: Array<Language>;
          }
        ).languages;
        // If there isn't, this entry is probably an alias. Check for the
        // `aliasFor` property instead.
      } else if (Object.hasOwn(localeSettings, 'aliasFor')) {
        const { aliasFor } = localeSettings as unknown as {
          readonly aliasFor: Country;
        };

        // And use whatever mappings the real locale has.
        const mappingsForRealLocale = countryLanguagesMap[aliasFor];

        languageList = (
          mappingsForRealLocale as unknown as {
            readonly languages: Array<Language>;
          }
        ).languages;

        // If the entry doesn't have either, it is malformed. Throw.
        if (!languageList) {
          throw new InvalidStateError(
            `Country "${locale}" is an alias for "${aliasFor}", which doesn't ` +
              `have any configured languages.`
          );
        }
      }

      if (!languageList) {
        throw new InvalidStateError(
          `Country "${locale}" does not have configured languages and isn't ` +
            `an alias for another country.`
        );
      }

      // Add to the list of lang-locales the combination of the current
      // locale and all of its configured languages.
      for (const language of languageList) {
        langLocales.add(`${language}-${locale}` as ConfiguredLocaleString);
      }
    }

    return Array.from(langLocales);
  }

  /**
   * Gets the current Site ID.
   * @returns A string of the current brand hyphenated with the current locale.
   * @example
   * 'SANUK-US'
   * 'HOKA-UK'
   */
  public get siteID(): SiteCountry {
    const { brand } = EnvironmentService;
    const { country } = this.currentLocale;
    return `${brand}-${country}`;
  }

  /**
   * Gets an object representing a {@link ILocale Locale} given a
   * {@link ConfiguredLocaleString locale string}.
   *
   * @param str - A string that either satisfies the {@link ConfiguredLocaleString} type,
   * or represents a {@link Country} or {@link Language}. In the latter two cases, the missing
   * component will take a default value.
   *
   * @returns An {@link ILocale} object.
   * @throws `InvalidArgumentError` if the passed in `str` is not a valid locale
   * string.
   */
  public getLocaleFromString(
    str: ConfiguredLocaleString | Language | Country
  ): ILocale {
    // Bad type casts can allow non-strings to come in. In those cases, throw an error
    // explaining what went wrong.
    if (typeof str !== 'string') {
      throw new InvalidArgumentError(
        `Value passed to parameter \`str\` must be type \`string\`, got value "${str}".`
      );
    }

    // Re-use the object if possible since we'll likely read the value more often than we'll rewrite it.
    const cachedIConfiguredLocaleString =
      this._ConfiguredLocaleStringRecord[str];
    if (cachedIConfiguredLocaleString) {
      return cachedIConfiguredLocaleString;
    }

    let [language, country] = str.split('-');
    const [defaultLang, defaultCountry] = ['en', 'US'];

    // If country is undefined, the string was only one part, rather than two.
    if (!country) {
      // If the `language` variable was indeed a language, fallback to the default country.
      if (Object.values(Language).includes(language as Language)) {
        country = defaultCountry;
      }
      // Otherwise, see if the `language` variable is a country instead.
      else if (Object.values(Country).includes(language as Country)) {
        language = defaultLang;
      }
      // Else, this string is bogus. Throw.
      else {
        throw new InvalidArgumentError(
          `\`getLocaleFromString\` was passed string "${str}" as a locale string, but this is not a valid locale string.`
        );
      }
    }

    // Create the ILocale result object.
    const result: ILocale = {
      language: language as Language,
      country: country as Country,

      /** @inheritdoc */
      toString() {
        // This is cast to `unknown` first because it is technically possible that
        // this is not actually a valid combination of language and country at
        // runtime. This scenario is very unlikely though, and if necessary
        // should use runtime checks instead.
        return `${language as Language}-${
          country as Country
        }` as unknown as ConfiguredLocaleString;
      }
    };

    // Add the result to the ILocale object cache.
    this._ConfiguredLocaleStringRecord[str] = result;

    return result;
  }

  /**
   * Gets the {@link Region} associated with a given {@link Country}.
   * @param country - The country to get the region for.
   * @returns The region associated with the provided country.
   * @throws {}
   * @example
   * getRegionOfCountry(Country.US); // 'NA'
   */
  public getRegionOfCountry(country: Country): Region {
    const region = Object.entries(regionCountriesMap).find(([, locales]) =>
      (locales as ReadonlyArray<Country>).includes(country)
    ) as [Region, ReadonlyArray<Country>] | undefined;

    if (!region) {
      throw new LikelyMisconfigurationError(
        `Could not find a region for country "${country}". This is likely an indication of misconfiguration.`
      );
    }

    return region[0];
  }

  /**
   * Retrieves a localized resource string for the current locale given a resource string
   * object containing lower-case locale keys with localized string values.
   * @param messageObj - The resource string object.
   * @returns The localized resource string.
   */
  private getMsgFromResourceObject(messageObj: Resource): string {
    const locale = this.currentLocale.toString();
    const lowerCaseLocale = locale.toLowerCase() as Lowercase<typeof locale>;
    const localizedString = messageObj[lowerCaseLocale];

    // If the locale is not found, fallback to the default locale.
    if (!localizedString) {
      return messageObj.default;
    }

    // If the localized string was found for the locale, return it.
    return localizedString;
  }

  /**
   * Retrieves a localized resource string for the current locale given a path to the
   * string. Resource strings are defined in the `lang` directory. Subdirectories represent
   * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @param langLocale - The lang-locale for the given message.
   * @returns The localized resource string.
   * @throws An error when in production and the argument is not an inline resource string
   * container object.
   */
  public msg<
    Path extends PathType<T>,
    Value extends PathValue<T, Path>,
    T = StringContainer
  >(msgID: Path | Resource, langLocale?: ILocale): Value {
    const locale = langLocale?.toString() ?? this.currentLocale.toString();

    // If the `msgID` is an object, then it is a resource string that has been inlined
    // by the static optimizations. In this case, we can just return the string directly
    // from the resource object of locale strings.
    if (msgID instanceof Object) {
      return this.getMsgFromResourceObject(msgID as Resource) as Value;
    }

    // If we're in production and we've come this far, it means that this method was
    // called, but not passed an inline resource string. This is a problem because
    // the static optimizations should have inlined all resource strings. We throw here
    // because if we allow it to go unchecked, we will suffer performance bloat from
    // always including all resource strings - something not acceptable for production.
    //
    // In storybook we load these strings dynamically. In the long run we will want to
    // run static generation and optimization for storybook as well.
    if (
      process.env.NODE_ENV === 'production' &&
      !(typeof __isolatedContext__ !== "undefined")
    ) {
      throw new Error(
        'For production builds, all resource strings should be inlined per our static optimizations. ' +
          `Something went wrong and the resource string for ID "${
            msgID as string
          }" was not inlined.`
      );
    }

    // Otherwise, we're in the development environment. In this case, we need to
    // dynamically load the resource strings for the current locale from the pre-built
    // objects that declare all of our resource strings. This method works best with hot-reloading.
    // Get the resource strings from the cache, or create a new one for this
    // locale.
    const resourceStrings = this.resourceStringCache.get(
      locale,
      () => {
        return new ResourceStrings(locale);
      },
      GetMethod.Sync
    );

    return resourceStrings.msg<Path, Value, T>(msgID as Path);
  }

  /**
   * Retrieves a localized resource string for the current locale given a path to the
   * string. Resource strings are defined in the `lang` directory. Subdirectories represent
   * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @param values - A record of values to be interpolated into the string. The possible
   * values are declared in the resource string itself.
   * @param locale - The lang-locale for the given message.
   * @returns The localized resource string formatted with the passed in values in
   * accordance with the Intl MessageFormat spec.
   *
   * @see {@link https://formatjs.io/docs/core-concepts/icu-syntax ICU Message syntax}
   * @see {@link https://formatjs.io/docs/intl-messageformat/#common-usage-example Common Usage Example}
   */
  public msgf<
    Path extends PathType<T>,
    Value extends PathValue<T, Path>,
    T = StringContainer
  >(
    msgID: Path,
    values: Record<string, Nullable<Primitive | ReactElement | JSX.Element>>,
    locale?: ILocale
  ): Value {
    const string = this.msg<Path, Value, T>(msgID, locale);

    return new IntlMessageFormat(
      string as string,
      locale?.toString() ?? this.currentLocale.toString()
    ).format(
      values as Record<
        string,
        | string
        | number
        | boolean
        | null
        | undefined
        | ReactElement
        | JSX.Element
      >
    ) as unknown as Value;
  }

  /**
   * Dynamically retrieves a localized resource string for the current locale given a path
   * to the string. Resource strings are defined in the `lang` directory. Subdirectories
   * represent the brand and then the locale. For example, the string
   * `lang/sanuk/en-US/general.ts`. The difference between this method and `msg` is that
   * this method will return a {@link StaleWhileRevalidate} object that will automatically
   * update the string if the resource ID changes.
   *
   * **Note:** This method is _not_ type safe. It is recommended to use `msg` or `msgf`
   * instead for type safety. Furthermore, this method should only be used when the
   * resource ID is not known at compile time. The method necessarily requires that all
   * resource strings be loaded at runtime, which can have a sizeable impact on the
   * perceived performance of the application.
   *
   * @param msgID - The resource string identifier for this resource. Will be a dot
   * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
   * the file in the `lang` directory, `key` is the key in the exported object, and
   * `nestedKey` could be a nested key in the object, and so on.
   * @returns The localized resource string.
   * @deprecated Use `msg` or `msgf` instead for type safety and better performance.
   */
  public msgDynamic(msgID: string): StaleWhileRevalidate<string> {
    const locale = this.currentLocale.toString();

    // Get the resource strings from the cache, or create a new one for this
    // locale.
    const resourceStrings = this.resourceStringCache.get(
      locale,
      () => {
        return new ResourceStrings(locale);
      },
      GetMethod.Sync
    );

    return resourceStrings.msgDynamic(msgID);
  }

  /**
   * Formats a number as currency based on the current locale
   * and options provided.
   * @param amount - The amount to format.
   * @param options - Options to pass to the formatter.
   * @returns The current currency code.
   */
  public formatCurrency(
    amount: number,
    options?: Intl.NumberFormatOptions
  ): string {
    const defaultCurrency = options?.currency ?? ('USD' as Currency);
    return new Intl.NumberFormat(this.currentLocale.toString(), {
      style: 'currency',
      currency: defaultCurrency,
      ...options
    }).format(amount);
  }

  /**
   * Formats a number as currency based on the current locale
   * and options provided, but without the currency symbol.
   * @param amount - The amount to format.
   * @param options - Options to pass to the formatter.
   * @returns The current currency code.
   * @example
   * I18NService.formatCurrencyNoSymbol(0); // '0.00' (if the current currency is USD)
   * I18NService.formatCurrencyNoSymbol(
   *  1234.56,
   *  { currency: 'JPY' }
   * ); // '1,235'
   */
  public formatCurrencyNoSymbol(
    amount: number,
    options?: Omit<Intl.NumberFormatOptions, 'style'>
  ): string {
    const defaultCurrency = options?.currency ?? this.currency;
    const currentLocale = this.currentLocale.toString();

    const { minimumFractionDigits, maximumFractionDigits } =
      new Intl.NumberFormat(currentLocale, {
        style: 'currency',
        currency: defaultCurrency,
        ...options
      }).resolvedOptions();

    return amount.toLocaleString(currentLocale, {
      minimumFractionDigits,
      maximumFractionDigits
    });
  }

  /**
   * Takes a date and formats it based on the current locale and options provided.
   * @param date - The date to format.
   * @param options - Options to pass to the formatter.
   * @returns The date formatted.
   */
  public formatDate(date: Date, options?: Intl.DateTimeFormatOptions): string {
    return new Intl.DateTimeFormat(
      this.currentLocale.toString(),
      options
    ).format(date);
  }

  /**
   * Retrieves the currency symbol based in current locale.
   * @returns - The current currency symbol.
   */
  public currentCurrencySymbol(): string {
    return new Intl.NumberFormat(this.currentLocale.toString(), {
      style: 'currency',
      currency: this.currency
    })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value as string;
  }

  /**
   * Given an arrival date or date range, returns a pretty formatted string for
   * display purposes. For each date, the format will be determined by the rules
   * of the specified language and locale. A hyphen will separate both dates
   * if two dates are provided.
   *
   * @param latestArrival - The latest date by which the shipment should be
   * delivered. If an `earliestArrival` date is provided, this will be the upper
   * bound in the delivery date range.
   *
   * @param earliestArrival - The earliest date at which the shipment could be
   * delivered. This value will be the lower bound in the delivery date range.
   *
   * @param locale - The {@link ILocale locale} to format for. Defaults to the
   * app's current language and country.
   *
   * @returns The formatted string.
   *
   * @example `Monday, 4/11`, `Monday, 4/11 - Wednesday, 4/14`
   */
  public formatDeliveryDate(
    latestArrival: Date,
    earliestArrival?: Nullable<Date>,
    locale: ConfiguredLocaleString = this.currentLocale.toString()
  ): string {
    const formattedLatestArrival = new Intl.DateTimeFormat(locale, {
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    }).format(latestArrival);

    const formattedEarliestArrival = earliestArrival
      ? new Intl.DateTimeFormat(locale, {
          weekday: 'long',
          month: 'numeric',
          day: 'numeric'
        }).format(earliestArrival)
      : null;

    return formattedEarliestArrival
      ? `${formattedEarliestArrival} - ${formattedLatestArrival}`
      : formattedLatestArrival;
  }
}

export default new I18NService();
